.l-menu {
  position: fixed;
  background-color: #e6f1f3;
  width: 100%;
  height: 100vh;
  top: 0;
  transform: translateX(100%);
  @include z-header(3);
  transition: all 0.5s ease;
  overflow-y: auto;
  overscroll-behavior-y: none;

  &.l-menu.js-menu-open {
    transform: translateX(0);
  }

  &__content {
    display: flex;
    justify-content: space-between;
    // align-items: center;
  }

  &__logo {
    width: 70px;
  }

  &__language-menu-wrapper {
    display: flex;
    align-items: center;
    height: 4rem;
    margin-right: 1rem;
    margin-top: 1rem;
  }

  &__inner {
    display: block;
    padding: 2.7rem 4rem 4rem;
  }

  &__nav li {
    font-family: $font-en;
    font-size: 2.4rem;
    margin-bottom: 2rem;

    & a {
      color: #1E2425;
      font-weight: bold;
    }
  }

  &__info {
    font-family: $font-en;
    font-size: 2rem;
    font-weight: bold;
    color: #1E2425;
    letter-spacing: 0.05em;
    margin-top: 6rem;

    &-cookie {
      margin-top: 2rem;
      margin-bottom: 6rem;
    }

    &-sns {
      width: 30px;
      display: inline-block;

      & a img {
        width: 100%;
      }
    }
  }
}

@media (--mobile) {
  .menu-link-about {
    font-size: 2.4rem;
    line-height: 2.8rem;
    margin-top: 0.4rem;

    &::before {
      content: '';
      display: inline-block;
      width: 1rem;
      height: 1rem;
      margin-right: 0.5rem;
      margin-bottom: 0.4rem;
      margin-left: 0.5rem;
      border-left: 1px solid #9cabad;
      border-bottom: 1px solid #9cabad;
    }
  }
}