@charset "UTF-8";
/**
 * Custom media queries
 * @example
 * @media (--mobile) {
 *    styles for mobile viewport
 * }
 */
/**
 * for mobile viewoport
 */
@import url(normalize.css);
@import url(swiper/swiper-bundle.min.css);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
@custom-media --mini (max-width: 320px);
@custom-media --phone (max-width: 519px);
@custom-media --tablet (min-width: 520px) and (max-width: 959px);
@custom-media --mobile (max-width: 959px);
@custom-media --large (min-width: 960px);
/**
 * for tablet and Pc. large viewport
 */
@custom-media --large (min-width: 960px);
@custom-media --desktop(min-width: 1280px);
/** http://bourbon.io/docs/#timing-functions */
/** example @include transition(all 5s $ease-in-circ);*/
/**
transition timing-function:
ease - cubic-bezier(0.25, 0.1, 0.25, 1.0) を指定したのと同じ（開始と完了を滑らかにする）（初期値）
linear - cubic-bezier(0.0, 0.0, 1.0, 1.0) を指定したのと同じ（一定）
ease-in - cubic-bezier(0.42, 0, 1.0, 1.0) を指定したのと同じ（ゆっくり始まる）
ease-out - cubic-bezier(0, 0, 0.58, 1.0) を指定したのと同じ（ゆっくり終わる）
ease-in-out - cubic-bezier(0.42, 0, 0.58, 1.0) を指定したのと同じ（ゆっくり始まってゆっくり終わる）
*/
/**
 * text ellipsis for block multiline
 */
/*
 unsupport IE, Edge, Fx

@mixin text-ellipsis-block($rows) {
  display: box;
  box-orient: vertical;
  line-clamp: $rows;
  overflow: hidden;
}
*/
/**
 * element size
 */
/**
 * position layout
 */
/**
 * images
 */
/**
* Variables
*/
/** extend */
@media (--large) {
  .ex-link {
    opacity: 1;
    transition: opacity 0.2s linear; }
    .ex-link:hover {
      opacity: .5; } }

/* == @import "../node_modules/normalize.css/normalize.css"; */
[v-cloak] {
  display: none; }

html {
  font-size: 62.5%; }

body {
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-weight: 500;
  font-size: 1.0rem;
  letter-spacing: 0;
  font-feature-settings: "palt";
  background-color: #E6F1F3; }

a {
  color: #000000;
  text-decoration: none; }

*, *:before, *:after {
  box-sizing: border-box; }

h1, h2, h3, h4, h5, h6, div, dl, dt, dd, p {
  margin: 0;
  padding: 0; }

ul, ol, li {
  padding: 0;
  margin: 0; }

li {
  list-style: none; }

img {
  vertical-align: bottom;
  height: auto;
  max-height: 100%;
  max-width: 100%;
  width: auto; }

figure {
  margin: 0;
  padding: 0; }

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

input[type="number"] {
  -moz-appearance: textfield; }

.l-main {
  line-height: 1.2; }

@media (--mobile) {
  html, body {
    width: 100%;
    height: 100%; }
  h1, h2, h3, h4, h5, h6, div, dl, dt, dd, p {
    margin: 0;
    padding: 0; }
  img {
    height: auto;
    max-height: 100%;
    max-width: 100%;
    width: auto; } }

button {
  border: none; }

.l-footer {
  position: relative; }

.l-footer__link__content {
  padding: 3rem 0;
  border-top: 1px solid #231815;
  border-bottom: 1px solid #231815;
  position: relative; }
  @media (--mobile) {
    .l-footer__link__content {
      overflow: hidden; } }

.footerSwiper {
  margin: 0 15vw;
  overflow: hidden; }
  @media (--mobile) {
    .footerSwiper {
      margin: 0 3.5rem;
      overflow: hidden; } }

.l-footer__inner {
  padding: 8rem 8rem 0rem;
  display: flex;
  justify-content: space-between; }
  @media (--mobile) {
    .l-footer__inner {
      display: block;
      padding: 6rem 2rem 4rem; } }

.l-footer__logo {
  width: 200px; }
  @media (--mobile) {
    .l-footer__logo {
      width: 180px;
      margin: 0 auto; } }
  .l-footer__logo img {
    width: 100%; }

.l-footer__wrapper {
  display: flex;
  margin-top: 4rem; }
  @media (--mobile) {
    .l-footer__wrapper {
      display: block; } }

.l-footer__nav {
  margin-right: 1.7rem; }
  @media (--mobile) {
    .l-footer__nav {
      margin: 4rem 0; } }

.l-footer__nav li {
  font-family: "Arimo", sans-serif;
  font-size: 1.4rem;
  margin-bottom: 0.8rem; }
  @media (--mobile) {
    .l-footer__nav li {
      font-size: 2.4rem;
      margin-bottom: 2rem; } }
  .l-footer__nav li a {
    color: #1E2425;
    font-weight: bold; }

.l-footer__info {
  line-height: 1.7;
  font-family: "Arimo", sans-serif;
  font-size: 1.4rem;
  font-weight: bold;
  color: #1E2425;
  letter-spacing: 0.08em;
  margin-left: 6rem; }
  @media (--mobile) {
    .l-footer__info {
      margin-left: 0;
      font-size: 2rem;
      display: flex;
      justify-content: space-between;
      align-items: end; } }
  .l-footer__info-sns {
    margin: 4.6rem 0 4rem 1rem;
    text-align: right;
    width: 20px;
    display: inline-block; }
    @media (--mobile) {
      .l-footer__info-sns {
        margin: 0;
        width: 30px; } }
    .l-footer__info-sns img {
      width: 100%; }

.copyright {
  font-size: 1rem;
  text-align: right;
  letter-spacing: 0.05em;
  display: block;
  padding: 0 8rem 7rem; }
  @media (--mobile) {
    .copyright {
      padding: 0 0 2.3rem 2rem;
      text-align: left; } }

.cookie__content {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 280px;
  background-color: rgba(30, 36, 37, 0.9);
  padding: 0 0 2rem 2rem;
  color: #fff;
  z-index: 99;
  display: none; }
  @media (--mobile) {
    .cookie__content {
      width: 100%; } }
  .cookie__content.none {
    visibility: hidden;
    opacity: 0; }
  .cookie__content.display {
    display: block; }

.cookie__tit {
  font-size: 1.6rem;
  margin-top: 2rem;
  line-height: 1.5;
  font-weight: bold; }
  .cookie__tit__wrapper {
    display: flex;
    justify-content: space-between;
    cursor: pointer; }

.cookie__close {
  position: relative;
  width: 30px;
  height: 30px; }
  .cookie__close span {
    display: block;
    width: 40px;
    height: 1px;
    position: absolute;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    transform: rotate(45deg);
    background-color: #fff; }
    .cookie__close span + span {
      transform: rotate(-45deg); }

.cookie__sub {
  font-size: 1.2rem;
  color: #fff;
  margin: 1rem 2rem 1.7rem 0;
  line-height: 1.6; }
  @media (--mobile) {
    .cookie__sub {
      margin: 1rem 1.5rem .7rem 0; } }
  .cookie__sub p a {
    color: #fff;
    text-decoration: underline solid #fff; }

.cookie__agree__wrapper {
  background-color: #fff;
  padding: 0.5rem 2.0rem;
  border-radius: 3rem;
  max-width: 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer; }
  @media (--mobile) {
    .cookie__agree__wrapper {
      margin-right: 1.5rem;
      margin-left: auto; } }
  .cookie__agree__wrapper .material-symbols-outlined {
    margin: 0; }

.cookie__agree__tex {
  font-size: 1.4rem;
  line-height: 1.5;
  font-weight: bold;
  color: #1E2425; }

.l-header {
  z-index: 3010;
  position: fixed;
  width: 100%;
  text-align: right;
  top: 1.5rem; }
  @media (--mobile) {
    .l-header {
      padding-right: 1rem;
      top: 0; } }
  .l-header__logo {
    margin-top: 3rem;
    padding-left: 3rem;
    position: relative;
    z-index: 3001; }
    @media (--mobile) {
      .l-header__logo {
        width: 200px;
        margin-top: 0;
        padding-left: 0;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%); } }
    .l-header__logo a {
      display: block;
      width: 240px; }
      @media (--mobile) {
        .l-header__logo a {
          width: 200px; } }
      .l-header__logo a img {
        width: 100%; }
  .l-header__inner {
    display: flex;
    justify-content: end;
    align-items: center;
    z-index: 9999; }
    @media (--mobile) {
      .l-header__inner {
        justify-content: space-between; } }
  .l-header-language-menu-wrapper {
    display: flex;
    align-items: center;
    height: 40px; }
  .l-header__language {
    position: relative;
    margin-right: 2rem; }
    @media (--mobile) {
      .l-header__language {
        margin-right: 1rem; } }
    .l-header__language select {
      appearance: none;
      background-color: rgba(0, 0, 0, 0);
      padding: 0.8rem 2.5rem;
      font-size: 1.2rem;
      font-family: "Arimo", sans-serif;
      border: 1px solid #231815;
      color: #1E2425; }
    .l-header__language::before {
      font-family: 'Material Icons';
      content: "language";
      position: absolute;
      font-size: 1.4rem;
      margin: 0.7rem; }
    .l-header__language::after {
      font-family: 'Material Icons';
      content: "arrow_drop_down";
      position: absolute;
      font-size: 1.4rem;
      top: 50%;
      right: 0.5rem;
      transform: translateY(-50%); }
  .l-header__menu {
    display: none; }
    @media (--mobile) {
      .l-header__menu {
        display: block;
        width: 40px;
        height: 40px;
        background-color: #003872;
        border-radius: 50%;
        position: relative;
        border: none;
        cursor: pointer; } }
    .l-header__menu-logo {
      display: none; }
      @media (--mobile) {
        .l-header__menu-logo {
          display: block;
          width: 70px;
          height: 70px; } }
    .l-header__menu-line::before {
      content: '';
      display: block;
      width: 16px;
      height: 1.5px;
      background-color: #fff;
      transform: translateY(-3px); }
    .l-header__menu-line::after {
      content: '';
      display: block;
      width: 16px;
      height: 1.5px;
      background-color: #fff;
      transform: translateY(3px); }

.l-header__menu-line {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); }

.l-header__menu-line.js-menu-open::before {
  top: 0px;
  left: 6px;
  transform: rotate(45deg); }

.l-header__menu-line.js-menu-open::after {
  top: 0px;
  position: absolute;
  transform: rotate(-45deg); }

.js-inview[data-inview="fade-top"] {
  opacity: 0;
  transform: translateY(30px);
  transition: transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.8s cubic-bezier(0.785, 0.135, 0.15, 0.86); }
  .js-inview[data-inview="fade-top"].is-inview {
    opacity: 1;
    transform: translateY(0); }

.c-pan__container ul {
  display: flex;
  flex-direction: row;
  justify-content: end;
  padding-top: 2rem;
  padding-bottom: 7.6rem;
  padding-right: 2rem; }
  @media (--mobile) {
    .c-pan__container ul {
      padding-bottom: 0; } }
  .c-pan__container ul li {
    font-family: "Arimo", sans-serif;
    font-size: 1.2rem; }

.material-symbols-outlined.c-pan-arrow {
  margin: 0 1rem; }

.material-symbols-outlined.arrow-color {
  color: #1e2425; }

.c-container {
  width: 80vw;
  border-left: 1px solid #231815;
  flex: 1 1 auto; }
  @media (--mobile) {
    .c-container {
      width: 100%;
      border-left: 0; } }

.border-left {
  border-left: 1px solid #231815; }
  @media (--mobile) {
    .border-left {
      border-left: 0px; } }

.c-introduction__container {
  padding: 12rem 0 12rem 8rem;
  border-bottom: 1px solid #231815; }
  @media (--mobile) {
    .c-introduction__container {
      padding: 8rem 0 8rem 2rem; } }
  .c-introduction__container.border-bottom_none {
    border-bottom: none; }

.c-pr {
  padding-inline-end: 8rem; }

.swiper-slide {
  width: 100%; }
  .swiper-slide img {
    width: 100%; }

.swiper-button-prev,
.swiper-button-next {
  width: 40px;
  height: 40px;
  background-color: #003872;
  border-radius: 50%; }

.swiper-button-next:hover::after {
  transform: translateX(3px); }

.swiper-button-prev:hover::after {
  transform: translateX(-3px); }

.swiper-button-prev::after,
.swiper-button-next::after {
  color: #fff;
  font-size: 1.4rem;
  transition: 0.2s; }

.swiper-button-prev.footer-button,
.swiper-button-next.footer-button {
  display: block;
  opacity: 1; }
  @media (--mobile) {
    .swiper-button-prev.footer-button,
    .swiper-button-next.footer-button {
      width: 25px;
      height: 25px; } }

.swiper-button-prev.footer-button {
  transform: translateX(8vw); }
  @media (--mobile) {
    .swiper-button-prev.footer-button {
      transform: translateX(0);
      position: absolute;
      left: 0;
      top: 58%; } }

.swiper-button-next.footer-button {
  transform: translateX(-8vw); }
  @media (--mobile) {
    .swiper-button-next.footer-button {
      transform: translateX(0);
      position: absolute;
      right: 0;
      top: 58%; } }

.swiper-button-prev.footer-button::after {
  position: absolute;
  top: 13px;
  left: 15px; }
  @media (--mobile) {
    .swiper-button-prev.footer-button::after {
      top: 7px;
      left: 8px;
      font-size: 12px; } }

.swiper-button-next.footer-button::after {
  position: absolute;
  top: 13px;
  left: 17px; }
  @media (--mobile) {
    .swiper-button-next.footer-button::after {
      top: 7px;
      left: 10px;
      font-size: 12px; } }

.nav-link-about {
  font-size: 1.2rem;
  line-height: 1.4rem;
  margin-top: 0.4rem; }
  @media (--mobile) {
    .nav-link-about {
      font-size: 2.4rem;
      line-height: 2.8rem; } }
  .nav-link-about::before {
    content: '';
    display: inline-block;
    width: 0.7rem;
    height: 0.7rem;
    border-left: 1px solid #9cabad;
    border-bottom: 1px solid #9cabad;
    margin-right: 0.4rem;
    margin-bottom: 0.4rem;
    margin-left: 0.4rem; }
    @media (--mobile) {
      .nav-link-about::before {
        width: 1rem;
        height: 1rem;
        margin-right: 0.5rem;
        margin-left: 0.5rem; } }

.link-hover:hover {
  color: #003872 !important; }

.link-hover:hover .material-symbols-outlined {
  color: #003872 !important; }

.l-menu {
  position: fixed;
  background-color: #e6f1f3;
  width: 100%;
  height: 100vh;
  top: 0;
  transform: translateX(100%);
  z-index: 3003;
  transition: all 0.5s ease;
  overflow-y: auto;
  overscroll-behavior-y: none; }
  .l-menu.l-menu.js-menu-open {
    transform: translateX(0); }
  .l-menu__content {
    display: flex;
    justify-content: space-between; }
  .l-menu__logo {
    width: 70px; }
  .l-menu__language-menu-wrapper {
    display: flex;
    align-items: center;
    height: 4rem;
    margin-right: 1rem;
    margin-top: 1rem; }
  .l-menu__inner {
    display: block;
    padding: 2.7rem 4rem 4rem; }
  .l-menu__nav li {
    font-family: "Arimo", sans-serif;
    font-size: 2.4rem;
    margin-bottom: 2rem; }
    .l-menu__nav li a {
      color: #1E2425;
      font-weight: bold; }
  .l-menu__info {
    font-family: "Arimo", sans-serif;
    font-size: 2rem;
    font-weight: bold;
    color: #1E2425;
    letter-spacing: 0.05em;
    margin-top: 6rem; }
    .l-menu__info-cookie {
      margin-top: 2rem;
      margin-bottom: 6rem; }
    .l-menu__info-sns {
      width: 30px;
      display: inline-block; }
      .l-menu__info-sns a img {
        width: 100%; }

@media (--mobile) {
  .menu-link-about {
    font-size: 2.4rem;
    line-height: 2.8rem;
    margin-top: 0.4rem; }
    .menu-link-about::before {
      content: '';
      display: inline-block;
      width: 1rem;
      height: 1rem;
      margin-right: 0.5rem;
      margin-bottom: 0.4rem;
      margin-left: 0.5rem;
      border-left: 1px solid #9cabad;
      border-bottom: 1px solid #9cabad; } }

.l-sidebar {
  display: flex; }
  @media (--mobile) {
    .l-sidebar {
      display: block; } }

.l-aside__container {
  width: 19.3vw;
  padding-left: 3.3rem;
  position: relative; }
  @media (--mobile) {
    .l-aside__container {
      display: none; } }

.l-aside__wrapper {
  position: sticky;
  top: 2rem;
  height: 35rem; }

.l-aside__logo {
  width: 140px; }
  .l-aside__logo img {
    width: 100%; }

.l-aside__inner {
  margin-top: 4rem; }
  .l-aside__inner li {
    font-family: "Arimo", sans-serif;
    font-size: 1.4rem;
    margin-bottom: 0.8rem;
    font-weight: bold; }
  .l-aside__inner a {
    color: #1e2425;
    letter-spacing: 0.1em; }

.p-cafe-aboutTop {
  background-color: #003872;
  width: 100%;
  height: 270px;
  position: relative; }
  @media (--tablet) {
    .p-cafe-aboutTop {
      height: 160px; } }
  @media (--mobile) {
    .p-cafe-aboutTop {
      height: 110px;
      margin-top: 6rem; } }
  .p-cafe-aboutTop span {
    font-size: 35px;
    color: #fff;
    font-weight: bold;
    position: absolute;
    bottom: 30px;
    left: 8rem; }
    @media (--mobile) {
      .p-cafe-aboutTop span {
        font-size: 14px;
        bottom: 12px;
        left: 1rem; } }
  .p-cafe-aboutTop-img {
    max-width: 626px; }
    @media (--tablet) {
      .p-cafe-aboutTop-img {
        width: auto;
        height: 160px; } }
    @media (--mobile) {
      .p-cafe-aboutTop-img {
        width: 210px;
        height: 110px; } }
    .p-cafe-aboutTop-img img {
      width: 100%;
      height: 100%; }
      @media (--tablet) {
        .p-cafe-aboutTop-img img {
          width: auto; } }
  .p-cafe-aboutTop h1 {
    font-size: 48px;
    color: #fff;
    position: absolute;
    top: 50%;
    right: 8rem;
    transform: translateY(-50%); }
    @media (--mobile) {
      .p-cafe-aboutTop h1 {
        font-size: 28px;
        right: 2.1rem; } }

.p-about-reception__container {
  padding: 0rem 8rem 12rem;
  border-bottom: 1px solid #231815; }
  @media (--mobile) {
    .p-about-reception__container {
      padding: 4.6rem 2rem 8rem; } }

.p-cafe-about__reception-img {
  aspect-ratio: 880 / 420; }
  @media (--mobile) {
    .p-cafe-about__reception-img {
      aspect-ratio: 1 / 1; } }

.p-cafe-about__reception-img img {
  width: 100%;
  height: 100%;
  object-fit: cover; }

.p-cafe-about__area-img {
  aspect-ratio: 880 / 420;
  padding-right: 8rem;
  margin-bottom: 5rem; }
  @media (--mobile) {
    .p-cafe-about__area-img {
      padding-right: 2rem;
      aspect-ratio: 1 / 1; } }

.p-cafe-about__area-img img {
  width: 100%;
  height: 100%;
  object-fit: cover; }

.p-about__cafeplan__container {
  display: flex; }
  @media (--mobile) {
    .p-about__cafeplan__container {
      flex-direction: column; } }

.p-about__cafeplan__map {
  position: relative;
  width: 60%;
  min-width: 450px; }
  @media (--mobile) {
    .p-about__cafeplan__map {
      padding-right: 2rem;
      width: 100%;
      min-width: auto;
      margin-bottom: 3rem; } }
  .p-about__cafeplan__map iframe {
    width: 100%;
    aspect-ratio: 550 / 360; }
    @media (--mobile) {
      .p-about__cafeplan__map iframe {
        aspect-ratio: 350 / 230; } }

.p-cafe-about__shop__title {
  font-family: "Arimo", sans-serif;
  font-size: 2.5rem;
  font-weight: bold;
  margin-top: 7rem;
  margin-bottom: 1rem; }
  @media (--mobile) {
    .p-cafe-about__shop__title {
      margin-top: 5rem;
      margin-bottom: 2rem; } }

.p-cafe-about__shop__gridwrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding-right: 8rem;
  gap: 40px; }
  @media (--mobile) {
    .p-cafe-about__shop__gridwrapper {
      grid-template-columns: 1fr 1fr;
      padding-right: 2rem;
      gap: 30px; } }

.p-cafe-about__shop__inner {
  width: auto; }
  .p-cafe-about__shop__inner img {
    width: 100%; }

.p-cafe-about__shop__sub {
  font-size: 1.4rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  line-height: 1.7; }
  @media (--mobile) {
    .p-cafe-about__shop__sub {
      font-size: 1.2rem;
      margin-top: 1.5rem;
      margin-bottom: 1rem; } }

.p-cafe-about__shop__price {
  font-size: 2.5rem;
  font-weight: bold;
  color: #003872;
  font-family: "Arimo", sans-serif; }
  @media (--mobile) {
    .p-cafe-about__shop__price {
      font-size: 2rem; } }
  .p-cafe-about__shop__price span {
    font-size: 1.4rem;
    font-weight: 400;
    margin-left: 0.6rem; }
    @media (--mobile) {
      .p-cafe-about__shop__price span {
        font-size: 1.2rem; } }

.p-cafe__container {
  width: 80vw;
  border-left: 1px solid #231815; }

.p-cafeTop {
  background-color: #003872;
  width: 100%;
  height: 400px;
  position: relative; }
  @media (--mobile) {
    .p-cafeTop {
      height: 190px;
      margin-top: 6rem; } }
  .p-cafeTop span {
    font-size: 53px;
    color: #fff;
    font-weight: bold;
    position: absolute;
    bottom: 30px;
    left: 8rem; }
    @media (--mobile) {
      .p-cafeTop span {
        font-size: 22px;
        left: 3rem; } }
  .p-cafeTop-img {
    max-width: 955px;
    object-fit: cover; }
    @media (--mobile) {
      .p-cafeTop-img {
        width: 390px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%); } }
    .p-cafeTop-img img {
      width: 100%;
      height: 100%; }

.p-cafe__info__container {
  padding: 0 6vw 12rem;
  border-bottom: 1px solid #231815;
  display: flex; }
  @media (--mobile) {
    .p-cafe__info__container {
      padding: 3.6rem 2rem 8rem;
      flex-direction: column-reverse; } }

.p-cafe__info__map {
  position: relative;
  width: 60%;
  min-width: 450px; }
  @media (--mobile) {
    .p-cafe__info__map {
      width: 100%;
      min-width: auto;
      margin-bottom: 3rem; } }
  .p-cafe__info__map iframe {
    width: 100%;
    aspect-ratio: 550 / 360; }
    @media (--tablet) {
      .p-cafe__info__map iframe {
        aspect-ratio: 350 / 230; } }
    @media (--mobile) {
      .p-cafe__info__map iframe {
        aspect-ratio: 350 / 480; } }

.p-cafe__info__text {
  width: 40%;
  margin-left: 5rem;
  line-height: 1.7; }
  @media (--mobile) {
    .p-cafe__info__text {
      margin-left: 0;
      width: 100%;
      margin-bottom: 3rem; } }

.p-cafe__info__heading {
  font-size: 1.6rem;
  font-weight: bold;
  color: #003872; }
  @media (--mobile) {
    .p-cafe__info__heading:nth-of-type(2) {
      margin-top: 2.2rem; } }

.p-cafe__info__desc {
  font-size: 1.4rem;
  margin-top: 0.4rem;
  margin-bottom: 1.7vw; }
  .p-cafe__info__desc-price {
    font-size: 1.4rem;
    margin-top: 0.6rem;
    margin-bottom: 2.4vw; }

.p-cafe__info__sub {
  font-size: 1.2rem;
  margin-bottom: 2.4vw; }

.p-cafe-menu__container {
  margin-bottom: 4rem; }

.p-cafe-menu__title {
  font-family: "Arimo", sans-serif;
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 1rem; }

.p-cafe__faq__section {
  padding: 12rem 8rem; }
  @media (--mobile) {
    .p-cafe__faq__section {
      padding: 8rem 2rem; } }

.p-cafe__faq__container {
  padding: 3rem;
  background-color: #fff;
  border-radius: 20px;
  margin-top: 5rem; }
  .p-cafe__faq__container:nth-of-type(2) {
    margin-top: 2rem; }
  @media (--mobile) {
    .p-cafe__faq__container {
      padding: 2rem; } }

.p-cafe__faq__ques {
  font-size: 1.6rem;
  font-weight: bold;
  margin-bottom: 1.3rem; }
  .p-cafe__faq__ques-q {
    font-size: 2rem;
    font-family: "Arimo", sans-serif;
    color: #fff; }
    .p-cafe__faq__ques-q::before {
      content: 'Q';
      display: inline-block;
      width: 3rem;
      height: 3rem;
      background-color: #003872;
      padding-top: 0.3rem;
      padding-left: 0.6rem;
      border-radius: 50%;
      margin-right: 0.6rem; }

.p-cafe__faq__ans {
  font-size: 1.4rem;
  line-height: 1.7; }
  @media (--mobile) {
    .p-cafe__faq__ans {
      display: flex; } }
  .p-cafe__faq__ans-a {
    font-size: 2rem;
    font-family: "Arimo", sans-serif;
    font-weight: bold;
    color: #fff;
    line-height: 1; }
    .p-cafe__faq__ans-a::before {
      content: 'A';
      display: inline-block;
      width: 3rem;
      height: 3rem;
      background-color: #d49d9c;
      padding-top: 0.3rem;
      padding-left: 0.7rem;
      border-radius: 50%;
      margin-right: 0.6rem; }

.p-cookie-pan {
  margin-top: 70px; }
  @media (--mobile) {
    .p-cookie-pan {
      margin-top: 50px; } }

.p-cookie__container {
  padding: 0rem 8rem 8rem; }
  @media (--mobile) {
    .p-cookie__container {
      padding: 6rem 2rem 8rem; } }
  .p-cookie__container h2 {
    font-size: 1.6rem;
    color: #003872;
    margin-top: 5rem;
    margin-bottom: 1rem; }

.p-cookie__desc {
  font-size: 1.4rem;
  line-height: 1.7; }

.p-cookie__list li {
  margin-top: 2rem;
  margin-bottom: 2rem; }

.p-cookie__title {
  margin-bottom: 0.5rem;
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 1.7; }

.p-cookie__ol-num {
  color: #9cabad;
  margin-right: 1rem; }

.p-cookie__ol-desc {
  font-size: 1.4rem;
  line-height: 1.7;
  margin-left: 2rem; }

.p-cookie__ul-desc-wrapper {
  display: flex; }

.p-cookie__ul-circ {
  display: inline-block;
  width: 1.4rem;
  height: 1.4rem;
  background-color: #9cabad;
  border-radius: 50%;
  margin-right: 0.6rem;
  margin-top: 0.4rem;
  flex: none; }

.p-cookie__ul-desc {
  font-size: 1.4rem;
  line-height: 1.7; }
  .p-cookie__ul-desc.-browser {
    margin-top: 0.5rem;
    text-decoration: underline; }

.p-cookie__browser-wrapper {
  display: flex; }

.p-cookie__browser__ul-circ {
  display: inline-block;
  width: 1.4rem;
  height: 1.4rem;
  background-color: #9cabad;
  border-radius: 50%;
  margin-right: 0.6rem;
  margin-top: 1rem;
  flex: none; }

.p-cookie__list-mt {
  margin-top: 2rem; }

.p-info-pan {
  margin-top: 50px; }

.p-info__container {
  padding: 0rem 8rem 8rem; }
  @media (--mobile) {
    .p-info__container {
      padding: 6rem 2rem 8rem; } }

.p-info__list__inner {
  padding: 2.5rem 2.2rem 2.5rem 2.2rem;
  background-color: #fff;
  display: flex;
  width: 100%;
  column-gap: 2rem; }
  @media (--mobile) {
    .p-info__list__inner {
      padding: 2rem;
      flex-direction: column; } }
  .p-info__list__inner-mt {
    margin-top: 1rem; }

.p-info__list-title {
  font-size: 1.4rem;
  color: #003872;
  width: 125px; }
  @media (--mobile) {
    .p-info__list-title {
      margin-bottom: 1rem; } }

.p-info__list-desc {
  font-size: 1.4rem;
  line-height: 1.4; }

.p-topFv {
  transform: translateY(-170px); }
  @media (--tablet) {
    .p-topFv {
      transform: translateY(0);
      height: auto; } }
  @media (--mobile) {
    .p-topFv {
      transform: translateY(0);
      height: 100vh; } }
  .p-topFv__container {
    display: flex;
    justify-content: center; }
    @media (--mobile) {
      .p-topFv__container {
        flex-wrap: wrap;
        height: 100%;
        margin-top: 6.3rem; } }
  .p-topFv__inner {
    width: calc(100% / 4);
    height: auto;
    position: relative; }
    @media (--mobile) {
      .p-topFv__inner {
        width: calc(100% / 2); } }
    .p-topFv__inner img {
      width: 100%;
      height: 100%; }
      @media (--mobile) {
        .p-topFv__inner img {
          object-fit: cover; } }
    .p-topFv__inner.-p1 {
      transform: translateY(140px); }
      @media (--mobile) {
        .p-topFv__inner.-p1 {
          transform: translateY(0); } }
    .p-topFv__inner.-p2 {
      transform: translateY(40px); }
      @media (--mobile) {
        .p-topFv__inner.-p2 {
          transform: translateY(0); } }
    .p-topFv__inner.-p3 {
      transform: translateY(100px); }
      @media (--mobile) {
        .p-topFv__inner.-p3 {
          transform: translateY(0); } }
  .p-topFv__title {
    font-family: "Arimo", sans-serif;
    font-size: 4rem;
    font-weight: bold;
    color: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
    writing-mode: vertical-lr; }
    @media (--mobile) {
      .p-topFv__title {
        font-size: 3.2rem; } }
  @media (--mobile) {
    .p-topFv-top {
      top: 0; } }
  @media (--mobile) {
    .p-topFv-right {
      right: 0;
      left: auto; } }

.line-height-1 {
  line-height: 1; }

.p-top-background-img {
  position: relative;
  top: 0;
  width: 100%;
  z-index: -1; }
  .p-top-background-img img {
    width: 100%; }

.p-topMainContiner {
  background-image: url("../images/top/info-01.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 700px;
  z-index: -1;
  position: relative;
  margin-top: -490px;
  transform: translateY(700px); }
  @media (--mobile) {
    .p-topMainContiner {
      display: none; } }

.p-header__container {
  width: 20vw;
  position: relative;
  top: 102px; }

.p-header__inner {
  margin-left: 3.3rem;
  position: sticky;
  top: 102px; }
  .p-header__inner li {
    font-family: "Arimo", sans-serif;
    font-size: 1.4rem; }
    .p-header__inner li a {
      color: #1e2425; }

.p-topInfo__overlay {
  background-color: rgba(0, 56, 114, 0.5);
  width: 430px;
  height: 700px;
  position: relative; }
  @media (--mobile) {
    .p-topInfo__overlay {
      background: linear-gradient(rgba(0, 56, 114, 0.5)), url("../images/top/info-sp.jpg");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      width: 100%;
      height: 100vh; } }

.p-topInfo__container {
  padding-top: 8rem;
  padding-left: 4rem;
  padding-right: 4rem; }
  @media (--mobile) {
    .p-topInfo__container {
      padding-top: 10rem;
      padding-left: 2rem;
      padding-right: 2rem; } }

.p-topInfo__title {
  color: #fff;
  font-family: "Arimo", sans-serif;
  font-size: 4rem;
  font-weight: bold;
  letter-spacing: 0.05em; }

.p-topInfo__logo {
  display: none; }
  @media (--mobile) {
    .p-topInfo__logo {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 70px;
      z-index: 99; }
      .p-topInfo__logo.is-fix {
        position: fixed; } }
  .p-topInfo__logo img {
    width: 100%; }

.twitter-timeline {
  margin-top: -1rem !important; }

.p-container {
  width: 80.07vw;
  flex: 1 1 auto; }
  @media (--mobile) {
    .p-container {
      width: 100%;
      position: relative; } }

.p-introduction__container {
  margin-left: 20vw;
  padding: 12rem 0 12rem 8rem;
  border-left: 1px solid #231815;
  border-bottom: 1px solid #231815; }
  .p-introduction__container.border-bottom_none {
    border-bottom: initial; }

.p-introduction__text {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5rem;
  padding-right: 8rem; }
  @media (--mobile) {
    .p-introduction__text {
      display: block;
      padding-right: 0;
      margin-bottom: 3rem; } }

.p-introduction__heading {
  font-family: "Arimo", sans-serif;
  font-size: 4.8rem;
  color: #003872;
  line-height: 1.1;
  letter-spacing: 0.13rem;
  font-weight: bold; }
  @media (--mobile) {
    .p-introduction__heading {
      font-size: 3.2rem; } }

.p-introduction__desc {
  font-size: 1.4rem;
  width: 44%;
  line-height: 1.7;
  margin-top: 3rem;
  letter-spacing: 0.04em; }
  @media (--mobile) {
    .p-introduction__desc {
      width: 100%;
      margin-top: 2rem;
      padding-right: 2rem; } }

.p-topAccess__map {
  width: 100%;
  position: relative;
  padding-top: 40%; }
  @media (--mobile) {
    .p-topAccess__map {
      height: 480px; } }
  .p-topAccess__map iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.p-topAccess__content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 3rem 0 3rem 8rem; }
  @media (--mobile) {
    .p-topAccess__content {
      display: block;
      padding: 2rem 0rem 8rem 2rem; } }

.p-topAccess__text {
  display: flex;
  align-items: flex-start; }

.p-topAccess__heading {
  display: inline-block;
  font-family: "Arimo", sans-serif;
  font-size: 2.5rem;
  color: #003872;
  margin-right: 3rem; }

.p-topAccess__address {
  display: inline-block;
  font-size: 1.4rem;
  line-height: 1.71; }
  @media (--mobile) {
    .p-topAccess__address {
      margin-top: 1rem;
      margin-bottom: 3rem; } }

.c-row {
  display: flex; }

.c-google-maps {
  position: relative;
  padding-bottom: 75%;
  height: 0;
  overflow: hidden; }

.c-google-maps iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important; }

.c-link__box__container {
  display: flex;
  justify-content: end;
  margin-right: 8rem; }
  @media (--mobile) {
    .c-link__box__container {
      margin-right: 2rem; } }

.c-link__box {
  padding: 0.7rem 1.4rem;
  background-color: #fff;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.4s; }
  .c-link__box .c-link {
    transition: all 0.4s; }
  .c-link__box.c-width {
    width: 13.7rem; }
  .c-link__box.c-more {
    width: max-content;
    margin-top: 5rem; }
    @media (-mobile) {
      .c-link__box.c-more {
        margin-top: 3rem; } }
  .c-link__box:hover {
    background-color: #003872; }
    .c-link__box:hover .c-link {
      color: #fff; }

.material-symbols-outlined {
  transition: all 0.4s; }

.c-link__box:hover .material-symbols-outlined {
  color: #fff !important; }

@media (max-width: 959px) {
  .c-link__box.c-more {
    margin-top: 3rem; } }

.c-link {
  font-family: "Arimo", sans-serif;
  font-size: 1.4rem;
  font-weight: bold;
  color: #1e2425;
  letter-spacing: 0.05rem; }

.material-symbols-outlined {
  font-size: 1.4rem;
  color: #9cabad;
  margin-top: 0.2rem;
  margin-left: 0.5rem; }

/**
* wordpress
* WYSIWYG compatchble style
*/
.c-wp-post_article strong {
  font-weight: bold; }

.c-wp-post_article em {
  font-style: italic; }

.c-wp-post_article ul {
  margin-left: 1em;
  display: block;
  list-style-type: disc !important;
  margin-before: 1em;
  margin-after: 1em;
  margin-start: 0px;
  margin-end: 0px;
  padding-start: 40px; }

.c-wp-post_article ol {
  display: block;
  list-style-type: decimal !important;
  margin-before: 1em;
  margin-after: 1em;
  margin-start: 0px;
  margin-end: 0px;
  padding-start: 40px; }

.c-wp-post_article li {
  display: list-item;
  text-align: match-parent; }

.c-wp-post_article img {
  width: auto; }

.c-wp-post_article .alignleft {
  display: inline;
  float: left; }

.c-wp-post_article .alignright {
  display: inline;
  float: right; }

.c-wp-post_article .aligncenter {
  display: block;
  margin-right: auto;
  margin-left: auto; }

.c-wp-post_article blockquote.alignleft,
.c-wp-post_article .wp-caption.alignleft,
.c-wp-post_article img.alignleft {
  margin: 0.4em 1.6em 1.6em 0; }

.c-wp-post_article blockquote.alignright,
.c-wp-post_article .wp-caption.alignright,
.c-wp-post_article img.alignright {
  margin: 0.4em 0 1.6em 1.6em; }

.c-wp-post_article blockquote.aligncenter,
.c-wp-post_article .wp-caption.aligncenter,
.c-wp-post_article img.aligncenter {
  clear: both;
  margin-top: 0.4em;
  margin-bottom: 1.6em; }

.c-wp-post_article .wp-caption.alignleft,
.c-wp-post_article .wp-caption.alignright,
.c-wp-post_article .wp-caption.aligncenter {
  margin-bottom: 1.2em; }

/**
 * clearfix micro
 */
.clearfix:after {
  content: "";
  clear: both;
  display: block; }

/**
 * text ellipsis., text...
 */
.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.u-hide {
  display: none; }

.u-click {
  cursor: pointer; }

@media (--large) {
  .for-mobile {
    display: none; } }

@media (--mobile) {
  .for-large {
    display: none; } }

.sp-none {
  display: block; }
  @media (--mobile) {
    .sp-none {
      display: none; } }

.sp-none-flex {
  display: flex; }
  @media (--mobile) {
    .sp-none-flex {
      display: none; } }

.pc-none {
  display: none; }
  @media (--mobile) {
    .pc-none {
      display: block; } }

.pc-none-flex {
  display: none; }
  @media (--mobile) {
    .pc-none-flex {
      display: flex; } }

.mr-none {
  margin-right: 0 !important; }

@media (--mobile) {
  .mt-md-3rem {
    margin-top: 3rem; } }

@media (--mobile) {
  .mb-md-none {
    margin-bottom: 0 !important; } }

.top-logo-y {
  transform: translateY(-7.8rem);
  top: 9.8rem; }

.t-x {
  transform: translateX(-1.1rem); }

/**
 * BrowserSync
 */
#__bs_notify__ {
  display: none !important;
  font-size: 8px !important;
  opacity: .25;
  max-height: 50px; }

.__debug_mihon__ {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  margin: 0 auto 0 auto;
  z-index: 100000;
  pointer-events: none;
  cursor: none;
  width: 100%;
  overflow: hidden;
  text-align: center;
  animation: __debug_mihon__ 1s infinite; }

.__debug_mihon__ {
  padding-top: 0px;
  margin-top: 0px; }

@-webkit-keyframes __debug_mihon__ {
  0% {
    opacity: 0; }
  60% {
    opacity: .5; }
  100% {
    opacity: .5; } }

/*
@example
 <div class="mihon">
  <img src="./images/sample1.png" alt="" class="type_pc">
  <img src="./images/sample2.png" alt="" class="type_sp">
</div>
*/
