.p-cafe-aboutTop {
  background-color: #003872;
  width: 100%;
  height: 270px;
  position: relative;
  @media (--tablet) {
    height: 160px;
  }
  @media (--mobile) {
    height: 110px;
    margin-top: 6rem;
  }
  & span {
    font-size: 35px;
    color: #fff;
    font-weight: bold;
    position: absolute;
    bottom: 30px;
    left: 8rem;
    @media (--mobile) {
      font-size: 14px;
      bottom: 12px;
      left: 1rem;
    }
  }
  &-img {
    max-width: 626px;
    @media (--tablet) {
      width: auto;
      height: 160px;
    }
    @media (--mobile) {
      width: 210px;
      height: 110px;
    }
    & img {
      width: 100%;
      height: 100%;
      @media (--tablet) {
        width: auto;
      }
    }
  }
  & h1 {
    font-size: 48px;
    color: #fff;
    position: absolute;
    top: 50%;
    right: 8rem;
    transform: translateY(-50%);
    @media (--mobile) {
      font-size: 28px;
      right: 2.1rem;
    }
  }
}

.p-about-reception__container {
  padding: 0rem 8rem 12rem;
  border-bottom: 1px solid #231815;
  @media (--mobile) {
    padding: 4.6rem 2rem 8rem;
  }
}

.p-cafe-about__reception-img {
  aspect-ratio: 880 / 420;
  @media (--mobile) {
    aspect-ratio: 1 / 1;
  }
}

.p-cafe-about__reception-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.p-cafe-about__area-img {
  aspect-ratio: 880 / 420;
  padding-right: 8rem;
  margin-bottom: 5rem;
  @media (--mobile) {
    padding-right: 2rem;
    aspect-ratio: 1 / 1;
  }
}
.p-cafe-about__area-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.p-about__cafeplan__container {
  display: flex;
  @media (--mobile) {
    flex-direction: column;
  }
}

.p-about__cafeplan__map {
  position: relative;
  width: 60%;
  min-width: 450px;
  @media (--mobile) {
    padding-right: 2rem;
    width: 100%;
    min-width: auto;
    margin-bottom: 3rem;
  }
  & iframe {
    width: 100%;
    aspect-ratio: 550 / 360;
    @media (--mobile) {
      aspect-ratio: 350 / 230;
    }
  }
}

.p-cafe-about__shop {
  &__title {
    font-family: $font-en;
    font-size: 2.5rem;
    font-weight: bold;
    margin-top: 7rem;
    margin-bottom: 1rem;
    @media (--mobile) {
      margin-top: 5rem;
      margin-bottom: 2rem;
    }
  }
  &__gridwrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding-right: 8rem;
    gap: 40px;
    @media (--mobile) {
      grid-template-columns: 1fr 1fr;
      padding-right: 2rem;
      gap: 30px;
    }
  }
  &__inner {
    width: auto;
    & img {
      width: 100%;
    }
  }
  &__sub {
    font-size: 1.4rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    line-height: 1.7;
    @media (--mobile) {
      font-size: 1.2rem;
      margin-top: 1.5rem;
      margin-bottom: 1rem;
    }
  }
  &__price {
    font-size: 2.5rem;
    font-weight: bold;
    color: #003872;
    font-family: $font-en;
    @media (--mobile) {
      font-size: 2rem;
    }
    & span {
      font-size: 1.4rem;
      font-weight: 400;
      margin-left: 0.6rem;
      @media (--mobile) {
        font-size: 1.2rem;
      }
    }
  }
}
