.l-footer {
  position: relative;
}

.l-footer__link {
  &__content {
    padding: 3rem 0;
    border-top: 1px solid #231815;
    border-bottom: 1px solid #231815;
    // overflow: hidden;
    position: relative;

    @media (--mobile) {
      // margin: 0rem 3.5rem;
      // border-top: 0;
      overflow: hidden;
    }
  }
}


.footerSwiper {
  // max-width: 970px;
  margin: 0 15vw;
  overflow: hidden;

  @media (--mobile) {
    margin: 0 3.5rem;
    overflow: hidden;
  }
}

.l-footer {
  &__inner {
    padding: 8rem 8rem 0rem;
    display: flex;
    justify-content: space-between;

    // align-items: center;
    @media (--mobile) {
      display: block;
      padding: 6rem 2rem 4rem;
    }
  }

  &__logo {
    width: 200px;

    @media (--mobile) {
      width: 180px;
      margin: 0 auto;
    }

    & img {
      width: 100%;
    }
  }

  &__wrapper {
    display: flex;
    margin-top: 4rem;

    @media (--mobile) {
      display: block;
    }
  }

  &__nav {
    margin-right: 1.7rem;

    @media (--mobile) {
      margin: 4rem 0;
    }
  }

  &__nav li {
    font-family: $font-en;
    font-size: 1.4rem;
    margin-bottom: 0.8rem;

    @media (--mobile) {
      font-size: 2.4rem;
      margin-bottom: 2rem;
    }

    & a {
      color: #1E2425;
      font-weight: bold;
    }
  }

  &__info {
    line-height: 1.7;
    font-family: $font-en;
    font-size: 1.4rem;
    font-weight: bold;
    color: #1E2425;
    letter-spacing: 0.08em;
    margin-left: 6rem;

    @media (--mobile) {
      margin-left: 0;
      font-size: 2rem;
      display: flex;
      justify-content: space-between;
      align-items: end;
    }

    &-sns {
      margin: 4.6rem 0 4rem 1rem;
      text-align: right;
      width: 20px;
      display: inline-block;

      @media (--mobile) {
        margin: 0;
        width: 30px;
      }

      & img {
        width: 100%;
      }
    }
  }
}

.copyright {
  font-size: 1rem;
  text-align: right;
  letter-spacing: 0.05em;
  display: block;
  padding: 0 8rem 7rem;

  @media (--mobile) {
    padding: 0 0 2.3rem 2rem;
    text-align: left;
  }
}

.cookie {
  &__content {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 280px;
    background-color: rgba(30, 36, 37, 0.9);
    padding: 0 0 2rem 2rem;
    color: #fff;
    z-index: 99;
    // transition: 1s;
    display: none;

    @media (--mobile) {
      width: 100%;
    }

    &.none {
      visibility: hidden;
      opacity: 0;
    }

    &.display {
      display: block;
    }
  }

  &__tit {
    font-size: 1.6rem;
    margin-top: 2rem;
    line-height: 1.5;
    font-weight: bold;

    &__wrapper {
      display: flex;
      justify-content: space-between;
      cursor: pointer;
    }
  }

  &__close {
    position: relative;
    width: 30px;
    height: 30px;

    & span {
      display: block;
      width: 40px;
      height: 1px;
      position: absolute;
      top: 50%;
      left: 50%;
      translate: -50% -50%;
      transform: rotate(45deg);
      background-color: #fff;

      &+span {
        transform: rotate(-45deg);
      }
    }
  }

  &__sub {
    font-size: 1.2rem;
    color: #fff;
    margin: 1rem 2rem 1.7rem 0;
    line-height: 1.6;

    @media (--mobile) {
      margin: 1rem 1.5rem .7rem 0;
    }

    & p a {
      color: #fff;
      text-decoration: underline solid #fff;
    }
  }

  &__agree {
    &__wrapper {
      background-color: #fff;
      padding: 0.5rem 2.0rem;
      border-radius: 3rem;
      max-width: 120px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;

      @media (--mobile) {
        margin-right: 1.5rem;
        margin-left: auto;
      }

      & .material-symbols-outlined {
        margin: 0;
      }
    }

    &__tex {
      font-size: 1.4rem;
      line-height: 1.5;
      font-weight: bold;
      color: #1E2425;
    }
  }
}