.p-cafe__container {
  width: 80vw;
  border-left: 1px solid #231815;
}

.p-cafeTop {
  background-color: #003872;
  width: 100%;
  height: 400px;
  position: relative;
  @media (--mobile) {
    height: 190px;
    margin-top: 6rem;
  }
  & span {
    font-size: 53px;
    color: #fff;
    font-weight: bold;
    position: absolute;
    bottom: 30px;
    left: 8rem;
    @media (--mobile) {
      font-size: 22px;
      left: 3rem;
    }
  }
  &-img {
    max-width: 955px;
    object-fit: cover;
    @media (--mobile) {
      width: 390px;
      // height: 190px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    & img {
      width: 100%;
      height: 100%;
    }
  }
}

.p-cafe__info {
  &__container {
    // padding: 0 8rem 12rem;
    padding: 0 6vw 12rem;
    border-bottom: 1px solid #231815;
    display: flex;
    @media (--mobile) {
      padding: 3.6rem 2rem 8rem;
      flex-direction: column-reverse;
    }
  }
  &__map {
    position: relative;
    width: 60%;
    min-width: 450px;
    @media (--mobile) {
      width: 100%;
      min-width: auto;
      margin-bottom: 3rem;
    }
    & iframe {
      width: 100%;
      aspect-ratio: 550 / 360;
      @media (--tablet) {
        aspect-ratio: 350 / 230;
      }
      @media (--mobile) {
        aspect-ratio: 350 / 480;
      }
    }
  }
  &__text {
    width: 40%;
    margin-left: 5rem;
    line-height: 1.7;
    @media (--mobile) {
      margin-left: 0;
      width: 100%;
      margin-bottom: 3rem;
    }
  }
  &__heading {
    font-size: 1.6rem;
    font-weight: bold;
    color: #003872;
    &:nth-of-type(2) {
      @media (--mobile) {
        margin-top: 2.2rem;
      }
    }
  }
  &__desc {
    font-size: 1.4rem;
    // margin-bottom: 2.2rem;
    margin-top: 0.4rem;
    margin-bottom: 1.7vw;
    &-price {
      font-size: 1.4rem;
      // margin-bottom: 3rem;
      margin-top: 0.6rem;
      margin-bottom: 2.4vw;
    }
  }
  &__sub {
    font-size: 1.2rem;
    // margin-bottom: 3rem;
    margin-bottom: 2.4vw;
  }
}

.p-cafe-menu {
  &__container {
    margin-bottom: 4rem;
  }
  &__title {
    font-family: $font-en;
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
}

.p-cafe__faq {
  &__section {
    padding: 12rem 8rem;
    @media (--mobile) {
      padding: 8rem 2rem;
    }
  }
  &__container {
    padding: 3rem;
    background-color: #fff;
    border-radius: 20px;
    margin-top: 5rem;
    &:nth-of-type(2) {
      margin-top: 2rem;
    }
    @media (--mobile) {
      padding: 2rem;
    }
  }
  &__ques {
    font-size: 1.6rem;
    font-weight: bold;
    margin-bottom: 1.3rem;
    &-q {
      font-size: 2rem;
      font-family: $font-en;
      color: #fff;
      &::before {
        content: 'Q';
        display: inline-block;
        width: 3rem;
        height: 3rem;
        background-color: #003872;
        padding-top: 0.3rem;
        padding-left: 0.6rem;
        border-radius: 50%;
        margin-right: 0.6rem;
      }
    }
  }
  &__ans {
    font-size: 1.4rem;
    line-height: 1.7;
    @media (--mobile) {
      display: flex;
    }
    &-a {
      font-size: 2rem;
      font-family: $font-en;
      font-weight: bold;
      color: #fff;
      line-height: 1;
      &::before {
        content: 'A';
        display: inline-block;
        width: 3rem;
        height: 3rem;
        background-color: #d49d9c;
        padding-top: 0.3rem;
        padding-left: 0.7rem;
        border-radius: 50%;
        margin-right: 0.6rem;
      }
    }
  }
}
