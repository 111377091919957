
html {
  font-size: 62.5%; //1.0rem = 10px
}
body {
  // font-family: $fontfamily;
  font-family: $font-ja;
  font-weight: 500;
  font-size: 1.0rem;
  letter-spacing: 0;
  font-feature-settings: "palt";
  background-color: #E6F1F3;
}
a {
  color: #000000;
  text-decoration: none;
}

*, *:before, *:after {
  box-sizing: border-box;
}

//text
h1, h2, h3, h4, h5 ,h6, div, dl, dt, dd, p {
  margin:0;
  padding: 0;
}

ul,ol,li {
  padding: 0;
  margin: 0;
}
li {
  list-style: none;
}

img {
  vertical-align: bottom;
  height: auto;
  max-height: 100%;
  max-width: 100%;
  width: auto;
}
figure{
  margin: 0;
  padding: 0;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"]{
  -moz-appearance:textfield;
}

.l-main {
  line-height: 1.2;
}

@media (--mobile) {
  html,body{
    width: 100%;
    height: 100%;
  }

  h1, h2, h3, h4, h5 ,h6, div, dl, dt,dd, p {
    margin:0;
    padding: 0;
  }

  img {
    height: auto;
    max-height: 100%;
    max-width: 100%;
    width: auto;
  }
}

button {
  border: none;
}
