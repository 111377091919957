.js-inview {
  &[data-inview="fade-top"] {
    opacity: 0;
    transform: translateY(30px);
    // transition: opacity 1.0s, transform 1.0s;
    transition: transform .5s cubic-bezier(.215,.61,.355,1),opacity .8s cubic-bezier(.785,.135,.15,.86);
    &.is-inview {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
