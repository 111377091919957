.p-top {
  // height: 100vh;
}
.p-topFv {
  transform: translateY(-170px);
  @media (--tablet) {
    transform: translateY(0);
    height: auto;
  }
  @media (--mobile) {
    transform: translateY(0);
    height: 100vh;
  }
  &__container {
    display: flex;
    justify-content: center;
    @media (--mobile) {
      flex-wrap: wrap;
      // padding-top: 6.3rem;
      height: 100%;
      margin-top: 6.3rem;
    }
  }
  &__inner {
    width: calc(100% / 4);
    height: auto;
    position: relative;
    @media (--mobile) {
      width: calc(100% / 2);
    }
    & img {
      width: 100%;
      height: 100%;
      @media (--mobile) {
        object-fit: cover;
      }
    }
    &.-p1 {
      transform: translateY(140px);
      @media (--mobile) {
        transform: translateY(0);
      }
    }
    &.-p2 {
      transform: translateY(40px);
      @media (--mobile) {
        transform: translateY(0);
      }
    }
    &.-p3 {
      transform: translateY(100px);
      @media (--mobile) {
        transform: translateY(0);
      }
    }
  }
  &__title {
    font-family: $font-en;
    font-size: 4rem;
    font-weight: bold;
    color: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
    writing-mode: vertical-lr;
    @media (--mobile) {
      font-size: 3.2rem;
    }
  }
  &-top {
    @media (--mobile) {
      top: 0;
    }
  }
  &-right {
    @media (--mobile) {
      right: 0;
      left: auto;
    }
  }
}

.line-height-1 {
  line-height: 1;
}

.p-top-background-img {
  position: relative;
  top: 0;
  width: 100%;
  z-index: -1;
  & img {
    width: 100%;
  }
}

.p-topMainContiner {
  background-image: url("../images/top/info-01.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 700px;
  z-index: -1;
  position: relative;
  margin-top: -490px;
  transform: translateY(700px);
  @media (--mobile) {
    display: none;
  }
}

.p-header {
  &__container {
    width: 20vw;
    // position: sticky;
    position: relative;
    top: 102px;
  }
  &__inner {
    margin-left: 3.3rem;
    position: sticky;
    top: 102px;
    & li {
      font-family: $font-en;
      font-size: 1.4rem;
    & a {
      color: #1e2425;
    }
    }
  }
}

.p-topInfo{
  &__overlay {
    background-color: rgba(0, 56, 114, 0.5);
    width: 430px;
    height: 700px;
    position: relative;
    @media (--mobile) {
      background: linear-gradient(rgba(0, 56, 114, 0.5)), url("../images/top/info-sp.jpg");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      width: 100%;
      // height: 900px;
      height: 100vh;
    }
  }
  &__container {
    padding-top: 8rem;
    padding-left: 4rem;
    padding-right: 4rem;
    @media (--mobile) {
      padding-top: 10rem;
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }
  &__title {
    color: #fff;
    font-family: $font-en;
    font-size: 4rem;
    font-weight: bold;
    letter-spacing: 0.05em;
  }
  &__logo {
    display: none;
    @media (--mobile) {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 70px;
      // position: sticky;
      z-index: 99;
      &.is-fix {
        // display: none;
        // display: block;
        position: fixed;
        // position: sticky;
      }
    }
    & img {
      width: 100%;
    }
  }
}

.twitter-timeline {
  margin-top: -1rem !important;
}

.p-container {
  width: 80.07vw;
  flex: 1 1 auto;
  @media (--mobile) {
    width: 100%;
    position: relative;
  }
}

.p-introduction {
  &__container {
    margin-left: 20vw;
    padding: 12rem 0 12rem 8rem;
    border-left: 1px solid #231815;
    border-bottom: 1px solid #231815;;
    &.border-bottom_none {
      border-bottom: initial;
    }
  }
  &__text {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5rem;
    padding-right: 8rem;
    @media (--mobile) {
      display: block;
      padding-right: 0;
      margin-bottom: 3rem;
    }
  }
  &__heading {
    font-family: $font-en;
    font-size: 4.8rem;
    color: #003872;
    line-height: 1.1;
    letter-spacing: 0.13rem;
    font-weight: bold;
    @media (--mobile) {
      font-size: 3.2rem;
    }
  }
  &__desc {
    font-size: 1.4rem;
    width: 44%;
    line-height: 1.7;
    margin-top: 3rem;
    letter-spacing: 0.04em;
    @media (--mobile) {
      width: 100%;
      margin-top: 2rem;
      padding-right: 2rem;
    }
  }
}

.p-topAccess {
  &__map {
    width: 100%;
    position: relative;
    padding-top: 40%;
    @media (--mobile) {
      height: 480px;
    }
    & iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  &__content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 3rem 0 3rem 8rem;
    @media (--mobile) {
      display: block;
      padding: 2rem 0rem 8rem 2rem;
    }
  }
  &__text {
    display: flex;
    align-items: flex-start;
  }
  &__heading {
    display: inline-block;
    font-family: $font-en;
    font-size: 2.5rem;
    color: #003872;
    margin-right: 3rem;
  }
  &__address {
    display: inline-block;
    font-size: 1.4rem;
    line-height: 1.71;
    @media (--mobile) {
      margin-top: 1rem;
      margin-bottom: 3rem;
    }
  }
}

