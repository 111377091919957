
/**
 * clearfix micro
 */
.clearfix:after {
    content: "";
    clear: both;
    display: block;
}

/**
 * text ellipsis., text...
 */
.text-ellipsis {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.u-hide{
  display:none;
}

.u-click {
  cursor:pointer;
}

@media (--large) {
  .for-mobile {
    display: none;
  }
}
@media (--mobile) {
  .for-large {
    display: none;
  }
}

.sp-none {
  display: block;
  @media (--mobile) {
    display: none;
  }
}

.sp-none-flex {
  display: flex;
  @media (--mobile) {
    display: none;
  }
}

.pc-none {
  display: none;
  @media (--mobile) {
    display: block;
  }
}

.pc-none-flex {
  display: none;
  @media (--mobile) {
    display: flex;
  }
}

.mr-none {
  margin-right: 0 !important;
}

.mt-md-3rem {
  @media (--mobile) {
    margin-top: 3rem;
  }
}

.mb-md-none {
  @media (--mobile) {
    margin-bottom: 0 !important;
  }
}

.top-logo-y {
  transform: translateY(-7.8rem);
  top: 9.8rem;
}
.t-x {
  transform: translateX(-1.1rem);
}
