.p-info-pan {
  margin-top: 50px;
}
.p-info {
  &__container {
    padding: 0rem 8rem 8rem;
    @media (--mobile) {
      padding: 6rem 2rem 8rem;
    }
  }
  &__list {
    // &__container {
    //   display: flex;
    // }
    &__inner {
      padding: 2.5rem 2.2rem 2.5rem 2.2rem;
      background-color: #fff;
      display: flex;
      width: 100%;
      column-gap: 2rem;
      @media (--mobile) {
        padding: 2rem;
        flex-direction: column;
      }
      &-mt {
        margin-top: 1rem;
      }
    }
    &-title {
      font-size: 1.4rem;
      color: #003872;
      width: 125px;
      @media (--mobile) {
        margin-bottom: 1rem;
      }
    }
    &-desc {
      font-size: 1.4rem;
      line-height: 1.4;
    }
  }
}
