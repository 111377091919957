.l-sidebar {
  display: flex;
  @media (--mobile) {
    display: block;
  }
}

.l-aside {
  &__container {
    width: 19.3vw;
    // margin-top: 2rem;
    padding-left: 3.3rem;
    position: relative;
    @media (--mobile) {
      display: none;
    }
  }
  &__wrapper {
    position: sticky;
    top: 2rem;
    height: 35rem;
  }
  &__logo {
    width: 140px;
    & img {
      width: 100%;
    }
  }
  &__inner {
    margin-top: 4rem;
    & li {
      font-family: $font-en;
      font-size: 1.4rem;
      margin-bottom: 0.8rem;
      font-weight: bold;
    }
    & a {
      color: #1e2425;
      letter-spacing: 0.1em;
    }
  }
}
