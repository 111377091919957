.p-cookie-pan {
  margin-top: 70px;
  @media (--mobile) {
    margin-top: 50px;
  }
}
.p-cookie {
  &__container {
    padding: 0rem 8rem 8rem;
    @media (--mobile) {
      padding: 6rem 2rem 8rem;
    }
    & h2 {
      font-size: 1.6rem;
      color: #003872;
      margin-top: 5rem;
      margin-bottom: 1rem;
    }
  }
  &__desc {
    font-size: 1.4rem;
    line-height: 1.7;
  }
  &__list li {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  &__title {
    margin-bottom: 0.5rem;
    font-size: 1.4rem;
    font-weight: bold;
    line-height: 1.7;
  }
  &__ol-num {
    color: #9cabad;
    margin-right: 1rem;
  }
  &__ol-desc {
    font-size: 1.4rem;
    line-height: 1.7;
    margin-left: 2rem;
  }
  &__ul-desc-wrapper {
    display: flex;
  }
  &__ul-circ {
    display: inline-block;
    width: 1.4rem;
    height: 1.4rem;
    background-color: #9cabad;
    border-radius: 50%;
    margin-right: 0.6rem;
    margin-top: 0.4rem;
    flex: none;
  }
  &__ul-desc {
    font-size: 1.4rem;
    line-height: 1.7;
    &.-browser {
      margin-top: 0.5rem;
      text-decoration: underline;
    }
  }
  &__browser-wrapper {
    display: flex;
  }
  &__browser__ul-circ {
    display: inline-block;
    width: 1.4rem;
    height: 1.4rem;
    background-color: #9cabad;
    border-radius: 50%;
    margin-right: 0.6rem;
    margin-top: 1rem;
    flex: none;
  }
}

.p-cookie__list-mt {
  margin-top: 2rem;
}
