.c-pan__container {
  & ul {
    display: flex;
    flex-direction: row;
    justify-content: end;
    padding-top: 2rem;
    padding-bottom: 7.6rem;
    padding-right: 2rem;
    @media (--mobile) {
      padding-bottom: 0;
    }
    & li {
      font-family: $font-en;
      font-size: 1.2rem;
    }
  }
}

.material-symbols-outlined.c-pan-arrow {
  margin: 0 1rem;
}

.material-symbols-outlined.arrow-color {
  color: #1e2425;
}

.c-container {
  width: 80vw;
  border-left: 1px solid #231815;
  flex: 1 1 auto;
  @media (--mobile) {
    width: 100%;
    border-left: 0;
  }
}

.border-left {
  border-left: 1px solid #231815;
  @media (--mobile) {
    border-left: 0px;
  }
}

.c-introduction__container {
  padding: 12rem 0 12rem 8rem;
  border-bottom: 1px solid #231815;
  @media (--mobile) {
    padding: 8rem 0 8rem 2rem;
  }
  &.border-bottom_none {
    border-bottom: none;
  }
}

.c-pr {
  padding-inline-end: 8rem;
}

.swiper-slide {
  width: 100%;
  & img {
    width: 100%;
  }
}

.swiper-button-prev,
.swiper-button-next {
  width: 40px;
  height: 40px;
  background-color: #003872;
  border-radius: 50%;
}

.swiper-button-next:hover::after {
  transform: translateX(3px);
}
.swiper-button-prev:hover::after{
  transform: translateX(-3px);
}

.swiper-button-prev::after,
.swiper-button-next::after {
  color: #fff;
  font-size: 1.4rem;
  transition: 0.2s;
}

.swiper-button-prev.footer-button,
.swiper-button-next.footer-button {
  display: block;
  opacity: 1;
  // transform: translateX(-10px);
  @media (--mobile) {
    width: 25px;
    height: 25px;
  }
}

.swiper-button-prev.footer-button {
  transform: translateX(8vw);
  @media (--mobile) {
    transform: translateX(0);
    position: absolute;
    left: 0;
    top: 58%;
  }
}
.swiper-button-next.footer-button {
  transform: translateX(-8vw);
  @media (--mobile) {
    transform: translateX(0);
    position: absolute;
    right: 0;
    top: 58%;
  }
}

.swiper-button-prev.footer-button::after {
  position: absolute;
  top: 13px;
  left: 15px;
  @media (--mobile) {
    top: 7px;
    left: 8px;
    font-size: 12px;
  }
}

.swiper-button-next.footer-button::after {
  position: absolute;
  top: 13px;
  left: 17px;
  @media (--mobile) {
    top: 7px;
    left: 10px;
    font-size: 12px;
  }
}

.nav-link-about {
  font-size: 1.2rem;
  line-height: 1.4rem;
  margin-top: 0.4rem;
  @media (--mobile) {
    font-size: 2.4rem;
    line-height: 2.8rem;
  }
  &::before {
    content: '';
    display: inline-block;
    width: 0.7rem;
    height: 0.7rem;
    border-left: 1px solid #9cabad;
    border-bottom: 1px solid #9cabad;
    margin-right: 0.4rem;
    margin-bottom: 0.4rem;
    margin-left: 0.4rem;
    @media (--mobile) {
      width: 1rem;
      height: 1rem;
      margin-right: 0.5rem;
      margin-left: 0.5rem;
    }
  }
}

.link-hover:hover {
  color: #003872 !important;
}

.link-hover:hover .material-symbols-outlined {
  color: #003872 !important;
}
