.l-header {
  @include z-header(10);
  position: fixed;
  width: 100%;
  text-align: right;
  top: 1.5rem;
  @media (--mobile) {
    // margin-top: 1rem;
    padding-right: 1rem;
    top: 0;
  }
  &__logo {
    margin-top: 3rem;
    padding-left: 3rem;
    position: relative;
    // width: 240px;
    @include z-header(1);
    @media (--mobile) {
      width: 200px;
      margin-top: 0;
      padding-left: 0;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    & a {
      display: block;
      width: 240px;
      @media (--mobile) {
        width: 200px;
      }
      & img {
        width: 100%;
      }
    }
  }
  &__inner {
    display: flex;
    justify-content: end;
    align-items: center;
    z-index: 9999;
    @media (--mobile) {
      justify-content: space-between;
    }
  }
  &-language-menu-wrapper {
    display: flex;
    align-items: center;
    height: 40px;
  }
  &__language {
    position: relative;
    margin-right: 2rem;
    @media (--mobile) {
      margin-right: 1rem;
    }
    & select {
      appearance: none;
      background-color: rgba(0, 0, 0, 0);
      padding: 0.8rem 2.5rem;
      font-size: 1.2rem;
      font-family: $font-en;
      border: 1px solid #231815;
      color: #1E2425;
    }
    &::before {
      font-family: 'Material Icons';
      content: "language";
      position: absolute;
      font-size: 1.4rem;
      // top: 50%;
      // left: 0.4rem;
      // transform: translateY(-50%);
      margin: 0.7rem;
    }
    &::after {
      font-family: 'Material Icons';
      content: "arrow_drop_down";
      position: absolute;
      font-size: 1.4rem;
      top: 50%;
      right: 0.5rem;
      transform: translateY(-50%);
    }
  }
  &__menu {
    display: none;
    @media (--mobile) {
      display: block;
      width: 40px;
      height: 40px;
      background-color: #003872;
      border-radius: 50%;
      position: relative;
      border: none;
      cursor: pointer;
    }
    &-logo {
      display: none;
      @media (--mobile) {
        display: block;
        width: 70px;
        height: 70px;
      }
    }
    &-line {
      &::before {
        content: '';
        display: block;
        width: 16px;
        height: 1.5px;
        background-color: #fff;
        // position: absolute;
        transform: translateY(-3px);
      }
      &::after {
        content: '';
        display: block;
        width: 16px;
        height: 1.5px;
        background-color: #fff;
        // position: absolute;
        transform: translateY(3px);
      }
    }
  }
}

.l-header__menu-line {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.l-header__menu-line.js-menu-open::before {
  top: 0px;
  left: 6px;
  transform: rotate(45deg);
}
.l-header__menu-line.js-menu-open::after {
  top: 0px;
  // left: 6px;
  position: absolute;
  transform: rotate(-45deg);
}
