@import url(https://fonts.googleapis.com/icon?family=Material+Icons);

.c-link__box__container {
  display: flex;
  justify-content: end;
  margin-right: 8rem;
  @media (--mobile) {
    margin-right: 2rem;
  }
}

.c-link__box {
  padding: 0.7rem 1.4rem;
  background-color: #fff;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.4s;
  & .c-link {
    transition: all 0.4s;
  }
  &.c-width {
    width: 13.7rem;
  }
  &.c-more {
    width: max-content;
    margin-top: 5rem;
    @media (-mobile) {
      margin-top: 3rem;
    }
  }
  &:hover {
    background-color: #003872;
    & .c-link {
      color: #fff;
    }
  }
}

.material-symbols-outlined {
  transition: all 0.4s;
}

.c-link__box:hover .material-symbols-outlined {
  color: #fff !important;
}


@media (max-width: 959px) {
  .c-link__box.c-more {
    margin-top: 3rem;
  }
}

.c-link {
  font-family: $font-en;
  font-size: 1.4rem;
  font-weight: bold;
  color: #1e2425;
  letter-spacing: 0.05rem;
  // padding-right: 0.3rem;
}

.material-symbols-outlined {
  font-size: 1.4rem;
  color: #9cabad;
  margin-top: 0.2rem;
  margin-left: 0.5rem;
}
